import * as styles from './Pagination.css'
import React, { ReactElement } from 'react'
import classNames from 'classnames'
import usePagination from '@/hooks/usePagination'
import MobilePagination from './MobilePagination/MobilePagination'
import Spinner from '@/components/Spinner/Spinner'
import DesktopPagination from './DesktopPagination/DesktopPagination'

type PaginatorProps = {
  totalPages: number
  currentPage: number
  lang?: 'pt' | 'es' | 'en'
  onChangePage: (page: number | string) => number
  onClickNextLabel?: (page: number | string) => number
  onClickPrevLabel?: (page: number | string) => number
  nextButtonLabel?: ReactElement
  prevButtonLabel?: ReactElement
  isMobile?: boolean
  customMobilePagination?: ReactElement
  mobileButtonLabel?: ReactElement | string
  onChangeMobilePage?: (page: number | string) => number
  isDisabled?: boolean
  extraLabel?: ReactElement
  isLoading?: boolean
}

const Pagination = ({
  totalPages,
  currentPage,
  lang = 'pt',
  onChangePage,
  nextButtonLabel,
  prevButtonLabel,
  onClickNextLabel,
  onClickPrevLabel,
  isMobile,
  customMobilePagination,
  mobileButtonLabel,
  onChangeMobilePage,
  isDisabled,
  extraLabel,
  isLoading,
}: PaginatorProps) => {
  const { pages } = usePagination(totalPages, currentPage)

  const renderPaginatorComponent = () => {
    if (isLoading) return <Spinner />

    if (isMobile) {
      return (
        <MobilePagination
          pages={pages}
          totalPages={totalPages}
          currentPage={currentPage}
          lang={lang}
          onChangePage={onChangePage}
          isDisabled={isDisabled}
          isLoading={isLoading}
          customMobilePagination={customMobilePagination}
          mobileButtonLabel={mobileButtonLabel}
          onChangeMobilePage={onChangeMobilePage}
        />
      )
    }

    return (
      <DesktopPagination
        pages={pages}
        totalPages={totalPages}
        currentPage={currentPage}
        lang={lang}
        onChangePage={(page) => onChangePage(page)}
        onClickNextLabel={onClickNextLabel}
        onClickPrevLabel={onClickPrevLabel}
        customNextButton={nextButtonLabel}
        customPrevButton={prevButtonLabel}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    )
  }

  const className = classNames({
    [styles.PaginationContainer]: true,
    [styles.PaginationLoading]: isLoading === true,
  })

  return (
    <div className={className}>
      {extraLabel && <div className={styles.ExtraLabel}>{extraLabel}</div>}
      {renderPaginatorComponent()}
    </div>
  )
}

export default Pagination
