import React, { ReactNode } from 'react'
import * as Toast from '@radix-ui/react-toast'

import {
  toastRoot,
  toastTitle,
  toastDescription,
  toastAction,
  toastButton,
  toastIcon,
  viewportPosition,
} from './Toast.css'

interface ToastProps extends Toast.ToastProps {
  open: boolean
  title: string
  description: string
  actionLabel?: string
  swipeDirection?: 'up' | 'down' | 'left' | 'right'
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
  duration?: number
  onActionClick?: () => void
  onOpenChange?: (open: boolean) => void
  altText?: string
  label?: string
  icon?: ReactNode
}

const ToastComponent: React.FC<ToastProps> = ({
  open = false,
  title,
  description,
  actionLabel,
  altText = 'Action',
  duration = 5000,
  onActionClick,
  onOpenChange,
  swipeDirection = 'right',
  label = 'Notification',
  icon,
  position = 'bottom-right',
  ...props
}) => {
  const handleAction = () => {
    if (onActionClick) {
      onActionClick()
    }
    onOpenChange?.(false)
  }

  return (
    <Toast.Provider
      duration={duration}
      label={label}
      swipeDirection={swipeDirection}
    >
      <Toast.Root
        {...props}
        className={toastRoot}
        open={open}
        onOpenChange={onOpenChange}
        duration={duration}
        data-swipe-direction={swipeDirection}
      >
        <Toast.Title className={toastTitle}>
          {icon && <span className={toastIcon}>{icon}</span>}
          {title}
        </Toast.Title>

        <Toast.Description className={toastDescription}>
          {description}
        </Toast.Description>

        {actionLabel && (
          <Toast.Action asChild altText={altText} className={toastAction}>
            <button className={toastButton} onClick={handleAction}>
              {actionLabel}
            </button>
          </Toast.Action>
        )}
      </Toast.Root>

      <Toast.Viewport className={viewportPosition[position]} />
    </Toast.Provider>
  )
}

export default ToastComponent
