import React, { ReactElement } from 'react'
import locales from '../locales/Pagination'
import { ProvisionalPages } from '@/hooks/usePagination'
import Button from '@/components/Button/Button'
import Spinner from '@/components/Spinner/Spinner'

type MobilePaginationProps = {
  totalPages: number
  currentPage: number
  lang?: 'pt' | 'es' | 'en'
  onChangePage: (page: number | string) => number
  onClickNextLabel?: (page: number | string) => number
  onClickPrevLabel?: (page: number | string) => number
  nextButtonLabel?: ReactElement
  prevButtonLabel?: ReactElement
  isMobile?: boolean
  customMobilePagination?: ReactElement
  mobileButtonLabel?: ReactElement | string
  onChangeMobilePage?: (page: number | string) => number
  isDisabled?: boolean
  extraLabel?: ReactElement | string
  isLoading?: boolean
  pages: ProvisionalPages[]
}

const MobilePagination = ({
  totalPages,
  currentPage,
  lang = 'pt',
  onChangePage,
  customMobilePagination,
  mobileButtonLabel,
  onChangeMobilePage,
  isDisabled,
  isLoading,
  pages,
}: MobilePaginationProps) => {
  if (isLoading) return <Spinner />

  if (pages.length <= 0 || currentPage >= totalPages) return <></>

  if (customMobilePagination) return customMobilePagination

  return (
    <Button
      disabled={isDisabled}
      size="small"
      onClick={() =>
        onChangeMobilePage
          ? onChangeMobilePage(currentPage + 1)
          : onChangePage(currentPage + 1)
      }
    >
      {mobileButtonLabel ? mobileButtonLabel : locales[lang].LOAD_MORE}
    </Button>
  )
}

export default MobilePagination
