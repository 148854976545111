import React from 'react'

import * as styles from './Breadcrumbs.css'

import IconArrowRight from '@/icons/navigation/iconArrowRight'

type BreadcrumbsProps = {
  list: {
    label?: string
    link?: string
  }[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ list }) => {
  return (
    <div className={styles.BreadcrumbsContent}>
      <ol className={styles.BreadcrumbsItem}>
        {list.map((crumb, i) => (
          <li className={styles.BreadcrumbsItem} key={`${crumb.label}-${i}`}>
            <a className={styles.Link} href={crumb.link}>
              <span>{crumb.label}</span>
            </a>
            {list.length - 1 > i && <IconArrowRight />}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Breadcrumbs
