import locales from './locales'

const nameCities = {
	SAO_PAULO: 'São Paulo',
	BELO_HORIZONTE: 'Belo Horizonte',
	RIO_DE_JANEIRO: 'Rio de Janeiro',
	RECIFE: 'Recife',
	GOIANIA: 'Goiânia',
	PORTO_ALEGRE: 'Porto Alegre',
	FLORIANOPOLIS: 'Florianópolis',
	SALVADOR: 'Salvador',
	CAMPINAS: 'Campinas',
	BRASILIA: 'Brasília',
	CURITIBA: 'Curitiba',
	FORTALEZA: 'Fortaleza',
}

const getSections = (lang: 'en' | 'pt' | 'es' = 'pt') => [
	{
		key: 1,
		title: locales[lang]['FIND_EVENTS'],
		options: [
			{
				name: locales[lang]['TODAY'],
				link: 'https://www.sympla.com.br/eventos/hoje',
			},
			{
				name: locales[lang]['TOMORROW'],
				link: 'https://www.sympla.com.br/eventos/amanha',
			},
			{
				name: locales[lang]['THIS_WEEK'],
				link: 'https://www.sympla.com.br/eventos/esta-semana',
			},
			{
				name: locales[lang]['THIS_WEEKEND'],
				link: '/eventos/este-fim-de-semana',
			},
			{
				name: locales[lang]['NEXT_WEEK'],
				link: 'https://www.sympla.com.br/eventos/proxima-semana',
			},
			{
				name: locales[lang]['THIS_MONTH'],
				link: 'https://www.sympla.com.br/eventos/este-mes',
			},
		],
	},
	{
		key: 2,
		title: locales[lang]['CITIES'],
		options: [
			{
				name: nameCities['SAO_PAULO'],
				link: 'https://www.sympla.com.br/eventos/sao-paulo-sp',
			},
			{
				name: nameCities['BELO_HORIZONTE'],
				link: 'https://www.sympla.com.br/eventos/belo-horizonte-mg',
			},
			{
				name: nameCities['RIO_DE_JANEIRO'],
				link: 'https://www.sympla.com.br/eventos/rio-de-janeiro-rj',
			},
			{
				name: nameCities['PORTO_ALEGRE'],
				link: 'https://www.sympla.com.br/eventos/porto-alegre-rs',
			},
			{
				name: nameCities['BRASILIA'],
				link: 'https://www.sympla.com.br/eventos/brasilia-df',
			},
			{
				name: nameCities['SALVADOR'],
				link: 'https://www.sympla.com.br/eventos/salvador-ba',
			},
			{
				name: nameCities['CURITIBA'],
				link: 'https://www.sympla.com.br/eventos/curitiba-pr',
			},
			{
				name: nameCities['RECIFE'],
				link: 'https://www.sympla.com.br/eventos/recife-pe',
			},
			{
				name: nameCities['FLORIANOPOLIS'],
				link: '/eventos/florianopolis-sc',
			},
			{
				name: nameCities['CAMPINAS'],
				link: 'https://www.sympla.com.br/eventos/campinas-sp',
			},
			{
				name: nameCities['FORTALEZA'],
				link: 'https://www.sympla.com.br/eventos/fortaleza-ce',
			},
			{
				name: nameCities['GOIANIA'],
				link: 'https://www.sympla.com.br/eventos/goiania-go',
			},
		],
	},
	{
		key: 3,
		title: locales[lang]['CATEGORIES'],
		options: [
			{
				name: locales[lang]['SYMPLA_PLAY'],
				link: 'https://www.sympla.com.br/eventos/sympla-play',
			},
			{
				name: locales[lang]['PARTIES_SHOWS'],
				link: 'https://www.sympla.com.br/eventos/show-musica-festa',
			},
			{
				name: locales[lang]['THEATER_SHOW'],
				link: 'https://www.sympla.com.br/eventos/teatro-espetaculo',
			},
			{
				name: locales[lang]['COURSES_WORKSHOPS'],
				link: 'https://www.sympla.com.br/eventos/curso-workshop',
			},
			{
				name: locales[lang]['CONGRESSES_TALK'],
				link: 'https://www.sympla.com.br/eventos/congresso-palestra',
			},
			{
				name: locales[lang]['SPORT'],
				link: 'https://www.sympla.com.br/eventos/esportivo',
			},
			{
				name: locales[lang]['TOURS'],
				link: 'https://www.sympla.com.br/eventos/experiencias',
			},
			{
				name: locales[lang]['GASTRONOMY'],
				link: 'https://www.sympla.com.br/eventos/gastronomico',
			},
			{
				name: locales[lang]['FREE'],
				link: 'https://www.sympla.com.br/eventos/gratis',
			},
			{
				name: locales[lang]['HEALTH_WELFARE'],
				link: 'https://www.sympla.com.br/eventos/saude-e-bem-estar',
			},
			{
				name: locales[lang]['ART_CULTURE_LEISURE'],
				link: 'https://www.sympla.com.br/eventos/arte-e-cultura',
			},
			{
				name: locales[lang]['KID'],
				link: 'https://www.sympla.com.br/eventos/infantil',
			},
			{
				name: locales[lang]['RELIGION_SPIRITUALITY'],
				link: '/eventos/religioso-espiritual',
			},
			{
				name: locales[lang]['GAMES_GEEK'],
				link: 'https://www.sympla.com.br/eventos/games-e-geek',
			},
			// { 'name': locales[lang]['REVEILLON'], 'link': '/eventos/reveillon' },
			{
				name: locales[lang]['FASHION_BEAUTY'],
				link: 'https://www.sympla.com.br/eventos/moda-e-beleza',
			},
		],
	},
	{
		key: 4,
		title: locales[lang]['ORGANIZERS'],
		options: [
			{
				name: locales[lang]['CREATE_EVENT_AT_SYMPLA'],
				link: 'https://www.sympla.com.br/solucoes',
			},
			{
				name: locales[lang]['SOLUTIONS'],
				link: 'https://www.sympla.com.br/solucoes',
			},
			{
				name: locales[lang]['INTEGRATIONS'],
				link: 'https://www.sympla.com.br/funcionalidades/integracoes',
			},
			{
				name: locales[lang]['SUCCESSFULL_CASES'],
				link: 'https://blog.sympla.com.br/blog-do-produtor/funcionalidades-sympla/cases-de-sucesso/',
			},
			{
				name: locales[lang]['SPEAK_TO_A_CONSULTANT'],
				link: 'https://www.sympla.com.br/fale-com-um-consultor?ca=footer',
			},
			{
				name: locales[lang]['HOMOLOGATED'],
				link: 'https://www.sympla.com.br/conecta',
			},
			{
				name: locales[lang]['API'],
				link: 'https://developers.sympla.com.br/api-doc/index.html',
			},
		],
	},
	{
		key: 5,
		title: locales[lang]['CREATE_YOUR_EVENT'],
		options: [
			{
				name: locales[lang]['COURSES_AND_WORKSHOPS'],
				link: 'https://www.sympla.com.br/para-produtores/crie-cursos-e-workshops',
			},
			{
				name: locales[lang]['SHOWS_AND_PARTIES'],
				link: 'https://www.sympla.com.br/para-produtores/crie-shows-e-festas',
			},
			{
				name: locales[lang]['SPORTIVE_EVENTS'],
				link: 'https://www.sympla.com.br/para-produtores/crie-eventos-esportivos',
			},
			{
				name: locales[lang]['GASTRONOMIC_EVENTS'],
				link: 'https://www.sympla.com.br/para-produtores/crie-eventos-gastronomicos',
			},
		],
	},
	{
		key: 6,
		title: locales[lang]['HELP'],
		options: [
			{
				name: locales[lang]['BUYERS_PARTICIPANT'],
				link: 'https://ajuda.sympla.com.br/hc/pt-br/',
			},
			{
				name: locales[lang]['EVENT_MANAGEMENT'],
				link: 'https://symplaprodutor.zendesk.com/hc/pt-br',
			},
		],
	},
]

export default getSections
