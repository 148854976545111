const baseTheme = {
	border: {
		radius: {
			'2': '2px',
			'4': '4px',
			'6': '6px',
			'8': '8px',
			'12': '12px',
			'16': '16px',
			'24': '24px',
			'32': '32px',
		},
	},
	breakpoints: {
		xs: '640px',
		sm: '830px',
		md: '1100px',
		lg: '1500px',
	},
	font: {
		family: {
			default: 'Open Sans, sans-serif',
			secondary: 'Raleway, sans-serif',
		},
		size: {
			'10': '10px',
			'12': '12px',
			'14': '14px',
			'16': '16px',
			'18': '18px',
			'20': '20px',
			'22': '22px',
			'24': '24px',
			'28': '28px',
			'32': '32px',
			'34': '34px',
			'36': '36px',
			'42': '42px',
			'58': '58px',
			'60': '60px',
		},
		weight: {
			light: '300',
			normal: '400',
			medium: '600',
			bold: '700',
			extraBold: '800',
		},
	},
	space: {
		'0': '0',
		'2': '2px',
		'4': '4px',
		'6': '6px',
		'8': '8px',
		'12': '12px',
		'16': '16px',
		'18': '18px',
		'24': '24px',
		'32': '32px',
		'40': '40px',
		'48': '48px',
		'56': '56px',
		'62': '62px',
		'64': '64px',
		'72': '72px',
		'80': '80px',
	},
	shadow: {
		over: {
			'10': '0 1px 2px 0 rgba(25, 31, 40, 0.15)',
			'20': '0 2px 10px 0 rgba(25, 31, 40, 0.1)',
			'30': '0 6px 24px 0 rgba(25, 31, 40, 0.15)',
			'40': '0 20px 36px 0 rgba(25, 31, 40, 0.2)',
		},
	},
	zIndex: {
		dropDown: '9',
	},
}

export const theme = {
	...baseTheme,
	color: {
		homolog: '#e67e22',
		stage: '#f1c40f',
		dev: '#27ae60',
		brand: {
			dark: '#007FD6',
			pure: '#0097FF',
			medium: '#2AAFFF',
			pale: '#E5F4FF',
		  },
		  analogue: {
			primary: {
			  dark: '#079455',
			  pure: '#17B26A',
			  medium: '#47CD89',
			  pale: '#ECFDF3',
			},
			secondary: {
			  dark: '#7839EE',
			  pure: '#875BF7',
			  medium: '#A48AFB',
			  pale: '#F5F3FF',
			},
		  },
		  moss: {
			dark: '#4F7A21',
			pure: '#669F2A',
			medium: '#86CB3C',
			pale: '#F5FBEE',
		  },
		  contrast: {
			primary: {
			  dark: '#E04F16',
			  pure: '#EF6820',
			  medium: '#F38744',
			  pale: '#FEF6EE',
			  transparent: 'rgba(255, 144, 54, 0.15)',
			},
			secondary: {
			  dark: '#D9202D',
			  pure: '#F03844',
			  medium: '#F96670',
			  pale: '#FEF2F3',
			  transparent: 'rgba(250, 70, 96, 0.15)',
			},
		  },
		neutral: {
			'0': '#FFFFFF',
			'10': '#F5F7F8',
			'25': '#DDE0E4',
			'50': '#B9BCC2',
			'75': '#848C9B',
			'90': '#4C576C',
			'100': '#191F28',
		},
	},
}

export const darkTheme = {
	...baseTheme,
	color: {
		homolog: '#e67e22',
		stage: '#f1c40f',
		dev: '#27ae60',
		brand: {
			dark: '#5CBCFF',
			pure: '#0097FF',
			medium: '#007FD6',
			pale: '#012844',
		},
		analogue: {
			primary: {
				dark: '#75E0A7',
				pure: '#17B26A',
				medium: '#079455',
				pale: '#053321',
			},
			secondary: {
				dark: '#C3B5FD',
				pure: '#875BF7',
				medium: '#7839EE',
				pale: '#2E125E',
			},
		},
		moss: {
			dark: '#ACDC79',
			pure: '#669F2A',
			medium: '#4F7A21',
			pale: '#1A280B',
		},
		contrast: {
			primary: {
				dark: '#F7B27A',
				pure: '#EF6820',
				medium: '#E04F16',
				pale: '#511C10',
				transparent: 'rgba(255, 144, 54, 0.15)',
			},
			secondary: {
				dark: '#FD9BA2',
				pure: '#F03844',
				medium: '#D9202D',
				pale: '#550C11',
				transparent: 'rgba(250, 70, 96, 0.15)',
			},
		},
	neutral: {
			'0': '#0a111f',
			'10': '#13203a',
			'25': '#1d2d4e',
			'50': '#B9BCC2',
			'75': '#dde0e4',
			'90': '#f5f7f8',
			'100': '#ffffff',
		},
	},
}
