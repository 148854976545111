import React from 'react'
import * as Checkbox from '@radix-ui/react-checkbox'
import * as styles from './Checkbox.css'

type SymplaCheckboxProps = Checkbox.CheckboxProps & {
  id: string
  label?: string
}

const SymplaCheckbox: React.FC<SymplaCheckboxProps> = ({
  label,
  id,
  ...props
}) => (
  <div className={styles.CheckboxContainer}>
    <Checkbox.Root className={styles.CheckboxRoot} id={id} {...props}>
      <Checkbox.Indicator />
    </Checkbox.Root>
    <label className={styles.CheckboxLabel} htmlFor={id}>
      {label}
    </label>
  </div>
)
export default SymplaCheckbox
