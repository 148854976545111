import React from 'react'

import ListItem from '@/components/ListItem/ListItem'
import List from '@/components/List/List'
import { Modal } from '@/components/Modal'
import { TemplateColumns } from '@/components/ContainerGrid/TemplateColumns'

import * as styles from './SelectModal.css'

type SelectModalProps = {
  open: boolean
  title: string | React.ReactNode
  accessibilityTitle: string
  options: {
    label?: string
    description?: string
    icon?: React.ReactNode
    param?: string
    value?: string
  }[]
  border?: boolean
  hideModal(): void
  onSelect?(...args: unknown[]): void
  columns?: TemplateColumns
  visibleRow?: number
  widthModal?: number
}

const SelectModal: React.FC<SelectModalProps> = ({
  open,
  title,
  accessibilityTitle,
  options,
  hideModal,
  onSelect,
  widthModal,
  columns = {
    xs: 1,
    sm: 2,
    md: 3,
  },
  visibleRow = 0,
  border = false,
}) => {
  return (
    <Modal
      title={title}
      accessibilityTitle={accessibilityTitle}
      width={widthModal}
      open={open}
      onClose={() => hideModal()}
    >
      <div id="select-modal" className={styles.SelectModalContainer}>
        <List columns={columns} visibleRow={visibleRow}>
          {options?.map((option, key) => (
            <ListItem
              border={border}
              key={`${option.label}-${key}`}
              onClick={() => {
                onSelect?.(option)
                hideModal()
              }}
            >
              <div className={styles.ItemContent}>
                {option.icon}
                {option.description ? (
                  <div className={styles.TextWrapper}>
                    <div className={styles.Label}>{option.label}</div>
                    <div className={styles.Description}>
                      {option.description}
                    </div>
                  </div>
                ) : (
                  <div className={styles.Label}>{option.label}</div>
                )}
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Modal>
  )
}

export default SelectModal
