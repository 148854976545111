import React from 'react'
import * as styles from './TextInput.css'

type TextInputProps = {
  title?: string
  optionalText?: string | React.ReactNode
  startAdorner?: React.ReactNode
  endAdorner?: React.ReactNode
  outsideAdorner?: React.ReactNode
  description?: string
  id: string
  hideArrow?: boolean
  error?: {
    hasError: boolean
    message: string
  }
} & React.InputHTMLAttributes<HTMLInputElement>

const TextInput: React.FC<TextInputProps> = ({
  id,
  title,
  startAdorner,
  endAdorner,
  optionalText = '',
  error,
  description,
  outsideAdorner,
  hideArrow,
  ...props
}) => {
  function renderLabel() {
    return (
      <label className={styles.Label} htmlFor={id}>
        {title}
        <span className={props.required ? styles.Required : ''}>
          {props.required ? '*' : optionalText}
        </span>
      </label>
    )
  }

  function renderDescription() {
    return <span className={styles.Description}>{description}</span>
  }

  function renderAdorner(
    adorner: React.ReactNode | undefined,
    position: 'left' | 'right'
  ) {
    const padding =
      position === 'left' ? styles.PaddingRight : styles.PaddingLeft
    return (
      adorner && (
        <div className={`${styles.AdornerContainer} ${padding}`}>{adorner}</div>
      )
    )
  }

  function renderError() {
    return (
      error?.hasError &&
      error?.message && <span className={styles.Error}>{error.message}</span>
    )
  }

  function renderOutsideAdorner() {
    return (
      outsideAdorner && (
        <div className={`${styles.AdornerContainer} ${styles.PaddingLeft}`}>
          {outsideAdorner}
        </div>
      )
    )
  }

  return (
    <div className={styles.Wrapper}>
      {title && renderLabel()}
      {description && renderDescription()}
      <div className={styles.InputContainer}>
        <div
          className={`${styles.TextInputContainer} ${error?.hasError && styles.TextInputContainerError}`}
        >
          {renderAdorner(startAdorner, 'left')}
          <input
            id={id}
            className={`${styles.TextInput} ${hideArrow && styles.HideInputArrow}`}
            type="text"
            {...props}
          />
          {renderAdorner(endAdorner, 'right')}
        </div>
        {renderOutsideAdorner()}
      </div>
      {renderError()}
    </div>
  )
}

export default TextInput
