import { useEffect, useState } from 'react'

export type ProvisionalPages = {
  text?: number | string
  disabled?: boolean
}

enum PagLayoutLimits {
  START_RANGE = 3,
  SIMPLE_LAYOUT = 7,
  TOP_FIVE_PAGES = 5,
}

const usePagination = (totalPages: number, currentPage: number) => {
  const [pages, setPages] = useState<Array<ProvisionalPages>>([])

  useEffect(() => {
    let provisionalPages: Array<ProvisionalPages> = []

    if (totalPages <= PagLayoutLimits.SIMPLE_LAYOUT) {
      for (let i = 1; i <= totalPages; i++) {
        provisionalPages.push({
          text: i,
        })
      }
    } else {
      provisionalPages = mountCompletePages()
    }
    setPages(provisionalPages)
  }, [totalPages, currentPage])

  const mountCompletePages = () => {
    const provisionalPages: Array<ProvisionalPages> = []

    // Check if you are in the top 3
    if (currentPage <= PagLayoutLimits.START_RANGE) {
      // Renders the first 5 pages
      for (let i = 1; i <= PagLayoutLimits.TOP_FIVE_PAGES; i++) {
        provisionalPages.push({
          text: i,
        })
      }
      injectExtraPages(true, totalPages).map((page) =>
        provisionalPages.push(page)
      )
    }

    // Check if it is among the last 3 numbers
    else if (currentPage >= totalPages - 2) {
      injectExtraPages(false, 1).map((page) => provisionalPages.push(page))
      // Renders the last 5 pages
      for (let i = totalPages - 4; i <= totalPages; i++) {
        provisionalPages.push({
          text: i,
        })
      }
    } else {
      injectExtraPages(false, 1).map((page) => provisionalPages.push(page))
      // Renders the current, previous and next page
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        provisionalPages.push({
          text: i,
        })
      }
      injectExtraPages(true, totalPages).map((page) =>
        provisionalPages.push(page)
      )
    }

    return provisionalPages
  }

  const injectExtraPages = (unshift: boolean, page: number) => {
    const extraPages: Array<ProvisionalPages> = [
      {
        text: page,
      },
    ]

    const reticencesLink = {
      text: '...',
      disabled: true,
    }

    unshift
      ? extraPages.unshift(reticencesLink)
      : extraPages.push(reticencesLink)

    return extraPages
  }

  const _resolvePaginationLink = (goToPage: number | string) => {
    const { pathname, search } = window.location
    const hasPage = new URLSearchParams(search).get('page') || ''
    let finalLink = `${pathname}${search}`

    if (search && hasPage) {
      finalLink = finalLink.replace(`page=${currentPage}`, `page=${goToPage}`)
      return finalLink
    }
    /** join page with ? */
    if (!search && !hasPage) {
      finalLink = `${finalLink}?page=${goToPage}`
      return finalLink
    }
    /** join page with & */
    if (search && !hasPage) {
      finalLink = `${finalLink}&page=${goToPage}`
      return finalLink
    }
    return ''
  }

  return { pages, _resolvePaginationLink }
}

export default usePagination
