import React from 'react'
import classNames from 'classnames'

import * as Styles from './Label.css'

type LabelProps = {
  isRequired?: boolean
} & React.LabelHTMLAttributes<HTMLLabelElement>

const Label = ({ isRequired, htmlFor, ...props }: LabelProps) => {
  const classes = classNames(Styles.Label, props.className, {
    [Styles.Required]: isRequired,
  })

  return <label htmlFor={htmlFor} {...props} className={classes}></label>
}

export default Label
