import React from 'react'
import classNames from 'classnames'

import * as styles from './Tag.css'

type TagProps = {
  children: React.ReactNode
  variant?:
    | 'analogue_primary_outline'
    | 'analogue_primary_contained'
    | 'analogue_secondary_outline'
    | 'analogue_secondary_contained'
    | 'contrast_primary_outline'
    | 'contrast_primary_contained'
    | 'contrast_secondary_outline'
    | 'contrast_secondary_contained'
    | 'neutral_outline'
    | 'neutral_contained'
    | 'neutral_25_outline'
    | 'neutral_25_contained'
    | 'default_outline'
    | 'default_contained'
  size?: 'sm' | 'md' | 'lg'
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  upperCase?: boolean
}

const Tag = ({
  children,
  variant = 'default_outline',
  size = 'sm',
  startAdornment = null,
  endAdornment = null,
  upperCase = true,
}: TagProps) => {
  const className = classNames({
    [styles.Tag]: true,
    [styles.SizeSmall]: size === 'sm',
    [styles.SizeMedium]: size === 'md',
    [styles.SizeLarge]: size === 'lg',
    [styles.AnaloguePrimaryOutline]: variant === 'analogue_primary_outline',
    [styles.AnaloguePrimaryContained]: variant === 'analogue_primary_contained',
    [styles.AnalogueSecondaryContained]:
      variant === 'analogue_secondary_contained',
    [styles.AnalogueSecondaryOutline]: variant === 'analogue_secondary_outline',
    [styles.ContrastPrimaryOutline]: variant === 'contrast_primary_outline',
    [styles.ContrastPrimaryContained]: variant === 'contrast_primary_contained',
    [styles.ContrastSecondaryOutline]: variant === 'contrast_secondary_outline',
    [styles.ContrastSecondaryContained]:
      variant === 'contrast_secondary_contained',
    [styles.NeutralOutline]: variant === 'neutral_outline',
    [styles.NeutralContained]: variant === 'neutral_contained',
    [styles.Neutral25Outline]: variant === 'neutral_25_outline',
    [styles.Neutral25Contained]: variant === 'neutral_25_contained',
    [styles.DefaultOutline]: variant === 'default_outline',
    [styles.DefaultContained]: variant === 'default_contained',
    [styles.Uppercase]: upperCase,
  })

  return (
    <div className={className}>
      {startAdornment && (
        <div className={styles.StartAdornment}>{startAdornment}</div>
      )}
      {children}
      {endAdornment && (
        <div className={styles.EndAdornment}>{endAdornment}</div>
      )}
    </div>
  )
}

export default Tag
