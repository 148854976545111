import React from 'react'
import * as SelectRadix from '@radix-ui/react-select'

import IconArrowDown from '@/icons/navigation/iconArrowDown'
import IconArrowUp from '@/icons/navigation/iconArrowUp'
import Label from '@/components/Label/Label'

import * as Styles from './Select.css'

type SelectOptions = {
  value: string
  text: string
  disabled?: boolean
}

export type SelectProps = {
  name: string
  value?: string
  defaultValue?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  label?: string
  options: SelectOptions[]
  errorMessage?: string
  contentPosition?: 'item-aligned' | 'popper'
  onOpenChange?(open: boolean): void
  onChange?(value: string): void
}

const Select = ({
  name,
  value,
  defaultValue,
  required,
  disabled,
  placeholder,
  label,
  options = [],
  errorMessage,
  contentPosition = 'popper',
  onOpenChange,
  onChange,
}: SelectProps) => (
  <div
    className={Styles.Content}
    data-state={errorMessage ? 'has-error' : undefined}
  >
    {label ? (
      <Label isRequired={required} htmlFor={name}>
        {label}
      </Label>
    ) : null}
    <SelectRadix.Root
      value={value}
      defaultValue={defaultValue}
      name={name}
      required={required}
      disabled={disabled}
      onValueChange={onChange}
      onOpenChange={onOpenChange}
    >
      <SelectRadix.Trigger
        className={Styles.Trigger}
        data-state={errorMessage ? 'has-error' : undefined}
      >
        <SelectRadix.Value placeholder={placeholder} />
        <SelectRadix.Icon className={Styles.TriggerIcon}>
          <IconArrowDown />
        </SelectRadix.Icon>
      </SelectRadix.Trigger>

      <SelectRadix.Portal>
        <SelectRadix.Content
          className={`${Styles.SelectContent} ${Styles.SelectContentPopper}`}
          position={contentPosition}
        >
          <SelectRadix.ScrollUpButton className={Styles.ScrollButton}>
            <IconArrowUp />
          </SelectRadix.ScrollUpButton>
          <SelectRadix.Viewport className={Styles.SelectViewport}>
            {options?.map(({ disabled, value, text }) => (
              <SelectRadix.Item
                key={value}
                className={Styles.SelectItem}
                value={value}
                disabled={disabled}
              >
                <SelectRadix.ItemText>{text}</SelectRadix.ItemText>
                <SelectRadix.ItemIndicator />
              </SelectRadix.Item>
            ))}
          </SelectRadix.Viewport>
          <SelectRadix.ScrollDownButton className={Styles.ScrollButton}>
            <IconArrowDown />
          </SelectRadix.ScrollDownButton>
        </SelectRadix.Content>
      </SelectRadix.Portal>
    </SelectRadix.Root>
    <div className={Styles.ErrorMessage}>{errorMessage}</div>
  </div>
)

export default Select
