import React from 'react'

import * as styles from './Spinner.css'

const Spinner = ({ ...props }: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className={styles.Spinner} role="status" aria-live="polite" {...props}>
    <span className={styles.Circle} />
    <span className={styles.Circle} />
    <span className={styles.Circle} />
  </div>
)

export default Spinner
