import React from 'react'
import { DayPicker, DayPickerProps } from 'react-day-picker'
import './CalendarDefaultTheme.css'
import { ptBR } from 'date-fns/locale'
import { CalendarContainer } from './Calendar.css'

export type {
  DateRange,
  SelectRangeEventHandler,
  DayPickerProps,
  Matcher,
} from 'react-day-picker'

const Calendar: React.FC<DayPickerProps> = ({ ...props }) => {
  return (
    <div className={CalendarContainer}>
      <DayPicker locale={props.locale || ptBR} {...props} />
    </div>
  )
}

export default Calendar
