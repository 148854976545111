import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import IconClose from '../../icons/navigation/iconClose'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import * as styles from './Modal.css'
import classNames from 'classnames'

type ModalProps = {
  open: boolean
  width?: number
  title?: string | React.ReactNode
  hideCloseButton?: boolean
  closeOnClickOutside?: boolean
  children: React.ReactNode
  onClose: () => void
  accessibilityTitle: string
  container?: HTMLElement | null
  hasSizePadding?: boolean
  fixed?: 'center' | 'bottom'
  mobileFullScreen?: boolean
}

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  width = 450,
  hideCloseButton,
  closeOnClickOutside = true,
  children,
  onClose,
  accessibilityTitle,
  hasSizePadding = true,
  container = null,
  fixed = 'center',
  mobileFullScreen = true,
}) => {
  const contentClassName = classNames({
    [styles.DialogContent]: true,
    [styles.DialogContentPadding]: hasSizePadding,
    [styles.DialogContentFixed.bottom]: fixed === 'bottom',
    [styles.DialogContentFixed.center]: fixed === 'center',
    [styles.DialogContentMobileFullScreen]: mobileFullScreen,
  })

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal container={container}>
        <Dialog.Overlay
          className={styles.DialogOverlay}
          onClick={() => closeOnClickOutside && onClose()}
        />
        <Dialog.Content
          className={contentClassName}
          style={{ width }}
          aria-describedby={accessibilityTitle}
        >
          {!hideCloseButton && (
            <Dialog.Close asChild>
              <button
                className={styles.CloseButton}
                onClick={onClose}
                aria-label="Fechar"
              >
                <IconClose />
              </button>
            </Dialog.Close>
          )}
          {title ? (
            <Dialog.Title
              className={styles.DialogTitle}
              title={accessibilityTitle}
            >
              {title}
            </Dialog.Title>
          ) : (
            <VisuallyHidden.Root>
              <Dialog.Title>{accessibilityTitle}</Dialog.Title>
            </VisuallyHidden.Root>
          )}
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
