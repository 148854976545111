import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as styles from './Dropdown.css'

type DropdownOption = {
  link: () => void
  icon: React.ReactNode
  label: string
}

type DropdownProps = {
  isOpen: boolean
  options: DropdownOption[]
  onOpenChange: () => void
  topDistance?: string
  side?: 'left' | 'right' | 'top' | 'bottom'
  container?: HTMLElement | null
  children?: React.ReactNode
  trigger: React.ReactNode
}

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  options,
  onOpenChange,
  topDistance = '30px',
  side = 'left',
  container = null,
  children,
  trigger,
}) => {
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={onOpenChange} modal={false}>
      <DropdownMenu.Trigger aria-label="Open Dropdown" asChild>
        {trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal forceMount container={container}>
        <DropdownMenu.Content
          className={`${styles.DropdownContainer} ${isOpen ? styles.enter : styles.leave}`}
          sideOffset={10}
          side={side}
          style={{ top: topDistance }}
        >
          {children}
          {options.map((item, index) => (
            <DropdownMenu.Item
              key={index}
              className={styles.DropdownOption}
              onSelect={item.link}
            >
              {item.icon} {item.label}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Dropdown
