/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react'

import IconUpload from '@/icons/action/iconUpload'
import IconTrash from '@/icons/action/iconTrash'
import IconLoading from '@/icons/action/iconLoading'

import {
  uploadContainer,
  imagePreviewContainer,
  imagePreview,
  deleteIconContainer,
  uploadIcon,
  uploadText,
  trashIcon,
  uploadInputContainer,
  uploadInputContent,
  uploadInputLoadingContainer,
} from './UploadInput.css'

interface UploadInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputId: string
  onFileSelect?: (files: File[]) => void
  initialValue?: string[]
  loading?: boolean
}

const UploadInput: React.FC<UploadInputProps> = ({
  inputId,
  onFileSelect,
  accept = 'image/*',
  initialValue = [],
  loading = false,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [previewUrls, setPreviewUrls] = useState<string[]>(initialValue)

  useEffect(() => {
    if (initialValue.length > 0) {
      setPreviewUrls(initialValue)
    }

    return () => {
      previewUrls.forEach((url) => URL.revokeObjectURL(url))
    }
  }, [initialValue])

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || [])
    generatePreviewUrls(selectedFiles)

    if (onFileSelect) {
      onFileSelect(selectedFiles)
    }

    event.target.value = ''
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const droppedFiles = Array.from(event.dataTransfer.files)
    generatePreviewUrls(droppedFiles)

    if (onFileSelect) {
      onFileSelect(droppedFiles)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDeleteImage = () => {
    previewUrls.forEach((url) => URL.revokeObjectURL(url))
    setPreviewUrls([])
  }

  const handleKeyDownDelete = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDeleteImage()
    }
  }

  const generatePreviewUrls = (files: File[]) => {
    previewUrls.forEach((url) => URL.revokeObjectURL(url))
    const urls = files.map((file) => URL.createObjectURL(file))
    setPreviewUrls(urls)
  }

  const handleClickFileInput = (
    event: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation()
    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.click()
    }
  }

  return (
    <div className={uploadInputContainer}>
      {previewUrls.length === 0 ? (
        <div
          className={uploadContainer}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleClickFileInput(event)
          }}
          onClick={handleClickFileInput}
        >
          {loading && (
            <div className={uploadInputLoadingContainer}>
              <IconLoading />
            </div>
          )}
          <input
            ref={inputRef}
            {...props}
            type="file"
            style={{ display: 'none' }}
            id={inputId}
            data-testid={inputId}
            accept={accept}
            onChange={handleFileSelect}
          />
          <label style={{ cursor: 'pointer' }}>
            <div className={uploadInputContent}>
              <IconUpload className={uploadIcon} width={24} height={24} />
              <p className={uploadText}>
                Clique ou arraste
                <br />
                imagens aqui
              </p>
            </div>
          </label>
        </div>
      ) : (
        <div className={imagePreviewContainer}>
          {loading && (
            <div className={uploadInputLoadingContainer}>
              <IconLoading />
            </div>
          )}

          {previewUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`preview ${index}`}
              className={imagePreview}
            />
          ))}
          <div
            className={deleteIconContainer}
            onClick={handleDeleteImage}
            onKeyDown={handleKeyDownDelete}
            role="button"
            tabIndex={0}
            aria-label="Delete image"
          >
            <IconTrash width={24} height={24} className={trashIcon} />
          </div>
        </div>
      )}
    </div>
  )
}

export default UploadInput
