import React from 'react'
import classNames from 'classnames'

import * as styles from './ListItem.css'

type ListItemProps = {
  border?: boolean
  borderRadius?: boolean
  hovered?: boolean
  children: React.ReactNode
  onClick?(...args: unknown[]): void
} & Partial<Pick<React.HTMLProps<HTMLAnchorElement>, 'href'>>

const ListItem: React.FC<ListItemProps> = ({
  children,
  border,
  borderRadius,
  hovered,
  href,
  onClick = () => {},
}) => {
  const classNameContainer = classNames({
    [styles.ListContainer]: true,
    [styles.ItemBorderRadius]: borderRadius,
    [styles.ItemHovered]: hovered,
  })
  const classNameItem = classNames({
    [styles.ListItem]: true,
    [styles.ItemBorder]: border,
  })

  return (
    <li className={classNameItem}>
      <div
        role="button"
        tabIndex={0}
        className={classNameContainer}
        onClick={onClick}
        onKeyDown={onClick}
      >
        {href ? (
          <a className={`${styles.ContentLink}`} href={href}>
            {children}
          </a>
        ) : (
          <div className={`${styles.ContentLink}`}>{children}</div>
        )}
      </div>
    </li>
  )
}

export default ListItem
