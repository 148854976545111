import React, { ReactNode } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import ArrowDown from '@/icons/navigation/iconArrowDown'
import IconSymplaDark from '@/icons/social/iconSymplaDark'
import IconAppleStore from '@/icons/social/iconAppleStore'
import IconPlayStore from '@/icons/social/iconPlayStore'
import { IconFacebook, IconTwitter, IconInstagram, IconLinkdin } from '@/icons'
import getSections from './helpers/getSections'
import getBottomInfo from './helpers/getBottomInfo'
import locales from './helpers/locales'

import * as styles from './Footer.css'

import varReplace from '../../utils/varReplace'

type SymplaAccordionProps = {
  lang?: 'pt' | 'es' | 'en'
  isCollapsible?: boolean
}

type PropsWithChildren<P = unknown> = P & { children: ReactNode }

const AccordionTrigger = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  { children, className, ...props }: PropsWithChildren<any>
) => {
  return (
    <Accordion.Header className={classNames(styles.AccordionShowButton)}>
      <Accordion.Trigger
        className={classNames(styles.AccordionTrigger, className)}
        {...props}
      >
        {children}
        <div
          className={classNames('AccordionChevron', styles.Chevron)}
          aria-hidden
        >
          <ArrowDown aria-hidden />
        </div>
      </Accordion.Trigger>
    </Accordion.Header>
  )
}

const AccordionContent = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  { children, className, ...props }: React.PropsWithChildren<any>
) => {
  return (
    <Accordion.Content
      className={classNames('AccordionContentWrapper', className)}
      {...props}
      forceMount
    >
      <div className={classNames('AccordionContent', styles.AccordionContent)}>
        {children}
      </div>
    </Accordion.Content>
  )
}

const Footer: React.FC<SymplaAccordionProps> = ({
  lang = 'pt',
  isCollapsible = true,
}) => (
  <div className={styles.FooterContainer}>
    <div className={styles.ContentWrapper}>
      <section className={styles.TopContent}>
        <IconSymplaDark className={styles.SymplaLogo} />
        <div className={styles.TopLeftContent}>
          <h4 className={styles.TopLeftContentTitle}>
            {locales[lang as keyof typeof locales]['BUY_FROM_APP']}
          </h4>
          <ul className={styles.AppUl}>
            <li className={styles.AppLi}>
              <a
                target="_blank"
                href="https://itunes.apple.com/br/app/sympla-ingressos-para-os-melhores/id1136318924"
                rel="noopener noreferrer"
                aria-label="Download na Apple Store"
              >
                <IconAppleStore />
              </a>
            </li>
            <li className={styles.AppLi}>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.sympla.tickets&hl=pt_BR"
                rel="noopener noreferrer"
                aria-label="Download no Google Play"
              >
                <IconPlayStore />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <div className={styles.FooterWrapper}>
        <Accordion.Root
          className={classNames(styles.AccordionRoot)}
          collapsible={isCollapsible}
          type="single"
          id="component-footer"
        >
          <Accordion.Item value="opcoes">
            <section className={classNames(styles.AccordionContentSection)}>
              <div className={classNames(styles.AccordionHeader)}>
                {getSections(lang).map((section, idx) => (
                  <div
                    className={classNames(styles.AccordionContentCollumn)}
                    key={`section-${idx}`}
                  >
                    <h4 className={classNames(styles.AccordionContentHeader)}>
                      {section.title}
                    </h4>
                    <AccordionContent
                      className={classNames(styles.AccordionContentBody)}
                    >
                      <ul className={classNames(styles.AccordionContentUl)}>
                        {section.options.map((option, idx) => (
                          <li
                            className={classNames(styles.AccordionContentLi)}
                            key={`section-option-${idx}`}
                          >
                            <a
                              target="_blank"
                              href={option.link}
                              rel="noopener noreferrer"
                              className={classNames(styles.Link)}
                            >
                              {option.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </div>
                ))}
              </div>
            </section>
            <AccordionTrigger>
              <>
                <span className={classNames('show', styles.ShowOptionText)}>
                  {locales[lang as keyof typeof locales]['SHOW_OPTIONS']}
                </span>
                <span className={classNames('hide', styles.ShowOptionText)}>
                  {locales[lang as keyof typeof locales]['HIDE_OPTIONS']}
                </span>
              </>
            </AccordionTrigger>
          </Accordion.Item>
        </Accordion.Root>
      </div>
      <section className={styles.BottomContent}>
        <div className={styles.SymplaInfoBox}>
          <div className={styles.SymplaInfoBoxScroller}>
            {getBottomInfo(lang).map((info, idx) => (
              <h4
                className={styles.SymplaInfoBoxTitle}
                key={`bottom-info-${idx}`}
              >
                <a
                  target="_blank"
                  href={info.link}
                  rel="noopener noreferrer"
                  className={styles.SymplaInfoBoxLink}
                >
                  {info.title}
                </a>
              </h4>
            ))}
          </div>
          <ul className={styles.SymplaInfoBoxIconUl}>
            <li className={styles.SymplaInfoBoxIconLi}>
              <a
                target="_blank"
                href="http://www.facebook.com/sympla"
                rel="noopener noreferrer"
                className={styles.SymplaInfoBoxIconLink}
                aria-label="Visite nosso Facebook"
              >
                <IconFacebook />
              </a>
            </li>
            <li className={styles.SymplaInfoBoxIconLi}>
              <a
                target="_blank"
                href="https://twitter.com/sympla/"
                rel="noopener noreferrer"
                className={styles.SymplaInfoBoxIconLink}
                aria-label="Visite nosso Twitter"
              >
                <IconTwitter />
              </a>
            </li>
            <li className={styles.SymplaInfoBoxIconLi}>
              <a
                target="_blank"
                href="https://www.instagram.com/sympla/"
                rel="noopener noreferrer"
                className={styles.SymplaInfoBoxIconLink}
                aria-label="Visite nosso Instagram"
              >
                <IconInstagram />
              </a>
            </li>
            <li className={styles.SymplaInfoBoxIconLi}>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/2413674/"
                rel="noopener noreferrer"
                className={styles.SymplaInfoBoxIconLink}
                aria-label="Visite nosso LinkedIn"
              >
                <IconLinkdin />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.CopyRight}>
          {varReplace(
            locales[lang as keyof typeof locales]['COPYRIGHT'],
            new Date().getFullYear()
          )}
        </div>
      </section>
    </div>
  </div>
)

export default Footer
