import * as styles from './ContainerGrid.css'
import classNames from 'classnames'
import { theme } from '../../styles/theme'
import { TemplateColumns } from './TemplateColumns'

const templateColumns = {
	repeat1: 1,
	repeat2: 2,
	repeat3: 3,
	repeat4: 4,
}

export const getClassesGrid = ({ md, sm, xs }: TemplateColumns) => {
	const className = classNames({
		[styles.DateSizeMd.Columns1]: md === templateColumns.repeat1,
		[styles.DateSizeMd.Columns2]: md === templateColumns.repeat2,
		[styles.DateSizeMd.Columns3]: md === templateColumns.repeat3,
		[styles.DateSizeMd.Columns4]: md === templateColumns.repeat4,
		[styles.DateSizeSm.Columns1]: sm === templateColumns.repeat1,
		[styles.DateSizeSm.Columns2]: sm === templateColumns.repeat2,
		[styles.DateSizeSm.Columns3]: sm === templateColumns.repeat3,
		[styles.DateSizeSm.Columns4]: sm === templateColumns.repeat4,
		[styles.DateSizeXs.Columns1]: xs === templateColumns.repeat1,
		[styles.DateSizeXs.Columns2]: xs === templateColumns.repeat2,
		[styles.DateSizeXs.Columns3]: xs === templateColumns.repeat3,
		[styles.DateSizeXs.Columns4]: xs === templateColumns.repeat4,
	})
	return className
}

export const getNumberColumns = ({ md, sm, xs }: TemplateColumns) => {
	if (typeof window !== 'undefined') {
		if (window.matchMedia(`(max-width: ${theme.breakpoints.xs})`)?.matches) {
			return xs
		} else if (
			window.matchMedia(`(max-width: ${theme.breakpoints.sm})`)?.matches
		) {
			return sm
		}
	}
	return md
}
