import locales from './locales'

const getBottomInfo = (lang: 'en' | 'pt' | 'es' = 'pt') => [
	{
		key: 1,
		title: locales[lang]['HOME'],
		link: 'https://www.sympla.com.br/',
	},
	{
		key: 2,
		title: locales[lang]['ABOUT'],
		link: 'https://www.sympla.com.br/sobre-sympla',
	},
	{
		key: 3,
		title: locales[lang]['BLOG'],
		link: 'https://blog.sympla.com.br/',
	},
	{
		key: 4,
		title: locales[lang]['JOBS'],
		link: 'https://www.sympla.com.br/trabalhe-conosco',
	},
	{
		key: 5,
		title: locales[lang]['TERMS_POLICIES'],
		link: 'https://termos-e-politicas.sympla.com.br/hc/pt-br',
	},
	{
		key: 6,
		title: locales[lang]['CODE_CONDUCT'],
		link: 'https://www.sympla.com.br/codigo-de-etica-e-conduta',
	},
	{
		key: 7,
		title: locales[lang]['PROSUS_LINK'],
		link: 'https://naspersstorageaccount.blob.core.windows.net/prodnaspersmedia/prosus/media/prosus/pdf/policies/prosus-human-rights-statement-(2021-04-16).pdf',
	},
]

export default getBottomInfo
