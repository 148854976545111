export default {
	en: {
		SHOW_MORE: 'Show more',
		SHOW_LESS: 'Show less',
	},
	pt: {
		SHOW_MORE: 'Ver mais',
		SHOW_LESS: 'Ver menos',
	},
	es: {
		SHOW_MORE: 'Ver mais',
		SHOW_LESS: 'Ver menos',
	},
}
