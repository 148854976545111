import React, { useState } from 'react'

import Calendar from '@/components/Calendar/Calendar'
import Modal from '@/components/Modal/Modal'
import Button from '@/components/Button/Button'
import FilterButton from '@/components/FilterButton/FilterButton'
import Typography from '@/components/Typography/Typography'

import * as styles from './CalendarModal.css'
import { DateRange } from 'react-day-picker'
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from 'date-fns'

const formatDateRangeLabel = ({
  localDate,
}: {
  localDate: DateRange | undefined
}) => {
  if (localDate?.from && localDate?.to) {
    const from = format(localDate.from, 'dd/MM/yyyy')
    const to = format(localDate.to, 'dd/MM/yyyy')
    const fromKeyword = format(localDate.from, 'yyyy-MM-dd')
    const toKeyword = format(localDate.to, 'yyyy-MM-dd')
    return {
      label: `${from} à ${to}`,
      keyword: `${fromKeyword},${toKeyword}`,
    }
  } else if (localDate?.from) {
    const from = format(localDate.from, 'dd/MM/yyyy')
    const fromKeyword = format(localDate.from, 'yyyy-MM-dd')
    return {
      label: from,
      keyword: `${fromKeyword}`,
    }
  }
  return null
}

type CalendarModalProps = {
  open: boolean
  title: string | React.ReactNode
  accessibilityTitle: string
  hideModal(): void
  onSelect?(...args: unknown[]): void
  isMobile?: boolean
  container?: HTMLElement | null
}

const CalendarModal: React.FC<CalendarModalProps> = ({
  open,
  hideModal,
  onSelect,
  title,
  accessibilityTitle,
  isMobile = false,
  container = null,
}) => {
  const [localDate, setLocalDate] = useState<DateRange | undefined>(undefined)
  const [selectedBtn, setSelectedBtn] = useState<number>(0)
  const removeFilter = () => {
    setLocalDate(undefined)
    setSelectedBtn(0)
  }

  const filterDate = () => {
    const formattedDateRange = formatDateRangeLabel({ localDate })
    onSelect?.(formattedDateRange)
    handleHideModal()
  }

  const handleHideModal = () => {
    removeFilter()
    hideModal()
  }

  const buttonDate = {
    today: {
      label: 'Hoje',
      indexBtn: 1,
      handleClick: () => {
        setLocalDate({ from: new Date() })
        setSelectedBtn(1)
      },
    },
    tomorrow: {
      label: 'Amanhã',
      indexBtn: 2,
      handleClick: () => {
        setLocalDate({
          from: addDays(new Date(), 1),
          to: addDays(new Date(), 1),
        })
        setSelectedBtn(2)
      },
    },
    thisWeek: {
      label: 'Esta Semana',
      indexBtn: 3,
      handleClick: () => {
        setLocalDate({
          from: startOfWeek(new Date()),
          to: endOfWeek(new Date()),
        })
        setSelectedBtn(3)
      },
    },
    nextWeek: {
      label: 'Próxima Semana',
      indexBtn: 4,
      handleClick: () => {
        setLocalDate({
          from: startOfWeek(addDays(new Date(), 7)),
          to: endOfWeek(addDays(new Date(), 7)),
        })
        setSelectedBtn(4)
      },
    },
    thisMonth: {
      label: 'Este Mês',
      indexBtn: 5,
      handleClick: () => {
        setLocalDate({
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
        })
        setSelectedBtn(5)
      },
    },
    nextMonth: {
      label: 'Próximo Mês',
      indexBtn: 6,
      handleClick: () => {
        const nextMonth = addDays(new Date(), 30)
        setLocalDate({
          from: startOfMonth(nextMonth),
          to: endOfMonth(nextMonth),
        })
        setSelectedBtn(6)
      },
    },
  }

  return (
    <Modal
      accessibilityTitle={accessibilityTitle}
      width={720}
      open={open}
      onClose={handleHideModal}
      container={container}
      hasSizePadding={false}
    >
      <div className={styles.CalendarModalContainer}>
        <div className={styles.CalendarModalText}>
          <Typography component="h3" type="medium" variant="1" weight="bold">
            {title}
          </Typography>
          <p className={styles.CalendarModalDescription}>
            Selecione uma ou mais datas
          </p>
        </div>

        <div className={styles.CalendarModalContent}>
          <div className={styles.DatesContent}>
            {Object.keys(buttonDate).map((date: string) => {
              const { label, indexBtn, handleClick } =
                buttonDate[date as keyof typeof buttonDate]
              return (
                <FilterButton
                  selectedItem={selectedBtn === indexBtn ? label : ''}
                  onClick={
                    selectedBtn === indexBtn ? removeFilter : handleClick
                  }
                  removeSelectedItem={removeFilter}
                  btnType="date"
                  upperCase={false}
                  key={label}
                >
                  {label}
                </FilterButton>
              )
            })}
          </div>
          <div className={styles.CalendarContent}>
            <Calendar
              numberOfMonths={isMobile ? 1 : 2}
              mode="range"
              selected={localDate}
              onSelect={setLocalDate}
            />
          </div>
        </div>
        <div className={styles.CalendarModalContentButton}>
          {localDate && (
            <Button
              onClick={removeFilter}
              variant="text"
              color="neutral"
              upperCase={false}
            >
              Limpar
            </Button>
          )}
          <div className={styles.ButtonResult}>
            <Button
              onClick={filterDate}
              upperCase={false}
              disabled={!localDate}
              fullWidthOnMobile
            >
              Mostrar resultados
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CalendarModal
