import React, { useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import {
  getClassesGrid,
  getNumberColumns,
} from '@/components/ContainerGrid/getGrid'
import { TemplateColumns } from '@/components/ContainerGrid/TemplateColumns'
import Button from '@/components/Button/Button'

import * as styles from './List.css'

import locales from './_locales/List'

type ListProps = {
  title?: string
  children: React.ReactElement | React.ReactElement[]
  columns?: TemplateColumns
  visibleRow?: number
  showMoreLabel?: string
  lang?: 'en' | 'pt' | 'es'
} & React.HTMLProps<HTMLUListElement>

const List: React.FC<ListProps> = ({
  children,
  columns = {
    xs: 1,
    sm: 1,
    md: 1,
  },
  lang = 'pt',
  title = '',
  visibleRow = 0,
  showMoreLabel = '',
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const arrayList = React.Children.toArray(children)
  const numberColumns = getNumberColumns(columns) || 1
  const visibleItems = visibleRow * numberColumns

  return (
    <div className={styles.ListContainer}>
      {title !== '' && <p className={styles.ListTitle}>{title}</p>}
      <Collapsible.Root
        className={styles.ListContainer}
        open={isOpen}
        onOpenChange={setIsOpen}
      >
        <ul className={`${styles.ListStyle} ${getClassesGrid(columns)}`}>
          {visibleRow ? arrayList.slice(0, visibleItems) : arrayList}
        </ul>
        <Collapsible.Content
          className={`${styles.ListContent} ${getClassesGrid(columns)}`}
          id="list-collapsible"
        >
          {!!isOpen && arrayList.slice(visibleItems, arrayList.length)}
        </Collapsible.Content>
      </Collapsible.Root>
      {!!visibleRow && React.Children.count(children) > visibleItems && (
        <Button variant="text" onClick={() => setIsOpen(!isOpen)}>
          {isOpen
            ? `${locales[lang]['SHOW_LESS']} ${showMoreLabel}`
            : `${locales[lang]['SHOW_MORE']} ${showMoreLabel}`}
        </Button>
      )}
    </div>
  )
}

export default List
