import * as styles from './DesktopPagination.css'
import React, { ReactElement } from 'react'
import IconArrowRight from '@/icons/navigation/iconArrowRight'
import IconArrowLeft from '@/icons/navigation/iconArrowLeft'
import Button from '@/components/Button/Button'
import locales from '../locales/Pagination'
import { ProvisionalPages } from '@/hooks/usePagination'
import Spinner from '@/components/Spinner/Spinner'

type DesktopPaginationProps = {
  totalPages: number
  currentPage: number
  lang?: 'pt' | 'es' | 'en'
  onChangePage: (page: number | string) => number
  onClickNextLabel?: (page: number | string) => number
  onClickPrevLabel?: (page: number | string) => number
  customNextButton?: ReactElement
  customPrevButton?: ReactElement
  isDisabled?: boolean
  isLoading?: boolean
  extraLabel?: ReactElement
  pages: ProvisionalPages[]
}

const DesktopPagination = ({
  totalPages,
  currentPage,
  lang = 'pt',
  onChangePage,
  customNextButton,
  customPrevButton,
  onClickNextLabel,
  onClickPrevLabel,
  isDisabled,
  isLoading,
  pages,
}: DesktopPaginationProps) => {
  const renderPrevButton = () => {
    if (customPrevButton) return customPrevButton

    const buttonAction = () =>
      onClickPrevLabel
        ? onClickPrevLabel(currentPage - 1)
        : onChangePage(currentPage - 1)
    return (
      <Button
        disabled={isDisabled}
        onClick={() => buttonAction()}
        variant="text"
        upperCase={false}
        rounded
      >
        <span className={styles.PrevNextWrapper}>
          <IconArrowLeft className={styles.IconArrowStyle} />
          {locales[lang]['PREVIOUS']}
        </span>
      </Button>
    )
  }

  const renderNextButton = () => {
    if (customNextButton) return customNextButton

    const buttonAction = () =>
      onClickNextLabel
        ? onClickNextLabel(currentPage + 1)
        : onChangePage(currentPage + 1)
    return (
      <Button
        disabled={isDisabled}
        type="button"
        onClick={() => buttonAction()}
        variant="text"
        upperCase={false}
        rounded
      >
        <div className={styles.PrevNextWrapper}>
          {locales[lang]['NEXT']}
          <IconArrowRight className={styles.IconArrowStyle} />
        </div>
      </Button>
    )
  }

  if (isLoading) return <Spinner />

  if (pages.length <= 0) return <></>

  return (
    <div className={styles.PaginationContainerItem}>
      {currentPage > 1 && renderPrevButton()}
      <div
        className={`${styles.PaginationContainerItem} ${styles.ContentNumberButton}`}
      >
        {pages.map((page, key) => (
          <Button
            onClick={() => onChangePage(page.text || '')}
            key={key}
            disabled={isDisabled || page.disabled}
            color={currentPage === page.text ? 'brand' : 'neutral'}
            variant={currentPage === page.text ? 'solid' : 'text'}
          >
            {page.text}
          </Button>
        ))}
      </div>

      {currentPage < totalPages && renderNextButton()}
    </div>
  )
}

export default DesktopPagination
