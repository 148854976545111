export default {
	en: {
		LOAD_MORE: 'Show more results',
		NEXT: 'Next',
		PREVIOUS: 'Previous',
	},
	pt: {
		LOAD_MORE: 'Mostrar mais resultados',
		NEXT: 'Próximo',
		PREVIOUS: 'Anterior',
	},
	es: {
		LOAD_MORE: 'Cargar más resultados',
		NEXT: 'Próximo',
		PREVIOUS: 'Anterior',
	},
}
