import * as Switch from '@radix-ui/react-switch'
import React from 'react'
import classNames from 'classnames'
import {
  containerStyle,
  switchLabelStyle,
  switchRootStyle,
  switchThumbStyle,
} from './Switch.css'

interface SwitchComponentProps
  extends React.ComponentPropsWithoutRef<'button'> {
  label?: string
  className?: string
  onChangeInput?: (checked: boolean) => void
  checked?: boolean
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
  label,
  className,
  onChangeInput,
  ...props
}) => {
  const handleChange = (checked: boolean) => {
    if (onChangeInput) {
      onChangeInput(checked)
    }
  }

  return (
    <div className={classNames(containerStyle, className)}>
      {label && (
        <label className={switchLabelStyle} htmlFor={props.id}>
          {label}
        </label>
      )}
      <Switch.Root
        className={switchRootStyle}
        onCheckedChange={handleChange}
        {...props}
      >
        <Switch.Thumb className={switchThumbStyle} />
      </Switch.Root>
    </div>
  )
}

export default SwitchComponent
