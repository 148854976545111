import React from 'react'

import Button from '@/components/Button/Button'
import IconArrowDown from '@/icons/navigation/iconArrowDown'
import IconCancel from '@/icons/action/iconCancelFill'

import * as styles from './FilterButton.css'

type FilterButtonProps = {
  selectedItem?: string
  children: string
  removeSelectedItem(...args: unknown[]): void
  btnType?: 'filter' | 'sort' | 'date'
  onClick?(...args: unknown[]): void
  upperCase: boolean
}

const FilterButton = ({
  selectedItem = '',
  children,
  removeSelectedItem,
  btnType = 'filter',
  onClick,
  upperCase = true,
}: FilterButtonProps) => {
  const removeFilter = () => {
    const payloadReset = {
      value: '',
      keyword: '',
    }
    removeSelectedItem(payloadReset)
  }

  const buildButton = () => {
    const templates = {
      filter: (
        <div
          className={`${styles.FilterButton} ${selectedItem ? '' : styles.FilterButtonBackground}`}
        >
          <Button
            size="micro"
            onClick={onClick}
            upperCase={upperCase}
            variant={selectedItem ? 'solid' : 'text'}
            color={selectedItem ? 'brand' : 'neutral'}
          >
            <div className={styles.ButtonLabel} key={children}>
              {selectedItem === ''
                ? [
                    children,
                    <IconArrowDown
                      key={children}
                      className={styles.ButtonDropdownIcon}
                    />,
                  ]
                : [
                    selectedItem,
                    <IconCancel
                      key={selectedItem}
                      className={styles.ButtonRemoveIcon}
                      id="remove-icon"
                      onClick={(e) => {
                        e.stopPropagation(), removeFilter()
                      }}
                    />,
                  ]}
            </div>
          </Button>
        </div>
      ),
      sort: (
        <div className={styles.FilterButton}>
          <Button size="micro" variant="text" color="neutral">
            <div className={styles.ButtonLabel}>
              {selectedItem === ''
                ? [
                    children,
                    <IconArrowDown
                      key={children}
                      className={styles.ButtonDropdownIcon}
                    />,
                  ]
                : [
                    selectedItem,
                    <IconArrowDown
                      key={selectedItem}
                      className={styles.ButtonDropdownIcon}
                    />,
                  ]}
            </div>
          </Button>
        </div>
      ),
      date: (
        <div
          className={`${styles.FilterButton} ${selectedItem ? '' : styles.FilterButtonBackground}`}
        >
          <Button
            size="micro"
            onClick={onClick}
            upperCase={upperCase}
            variant={selectedItem ? 'solid' : 'text'}
            color={selectedItem ? 'brand' : 'neutral'}
          >
            <div className={styles.ButtonLabel} key={children}>
              {selectedItem === ''
                ? [
                    <span key={children} className={styles.DateLabel}>
                      {children}
                    </span>,
                  ]
                : [
                    selectedItem,
                    <IconCancel
                      key={selectedItem}
                      className={styles.ButtonRemoveIcon}
                      id="remove-icon"
                      onClick={(e) => {
                        e.stopPropagation(), removeFilter()
                      }}
                    />,
                  ]}
            </div>
          </Button>
        </div>
      ),
    }

    return templates[btnType]
  }

  return <>{buildButton()}</>
}

export default FilterButton
