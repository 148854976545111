export default {
	en: {
		BUY_FROM_APP: 'Buy from APP',
		FIND_EVENTS: 'Find Events',
		CITIES: 'Cities',
		CATEGORIES: 'Categories',
		ORGANIZERS: 'Organizers',
		CREATE_YOUR_EVENT: 'Create Your Event',
		HELP: 'Help',
		TODAY: 'Today',
		TOMORROW: 'Tomorrow',
		THIS_WEEK: 'This week',
		THIS_WEEKEND: 'This weekend',
		NEXT_WEEK: 'Next week',
		THIS_MONTH: 'This month',
		ALL_DATES: 'All Dates',
		SYMPLA_PLAY: 'Sympla Play',
		PARTIES_SHOWS: 'Parties and shows',
		THEATER_SHOW: 'Theater and shows',
		LEARN: 'Learning',
		ART_CULTURE_LEISURE: 'Art, Culture and Leisure',
		SPORT: 'Sport',
		WELLNESS: 'Wellness',
		CONGRESSES_TALK: 'Congresses and talks',
		GASTRONOMY: 'Gastronomy',
		GASTRONOMIC: 'Gastronomic',
		FREE: 'Free',
		MEETING_NETWORKING: 'Meeting and Networking',
		RELIGION_SPIRITUALITY: 'Religion and Spirituality',
		ONLINE_COURSES: 'Online Courses',
		RELIGIOUS: 'Religious',
		TECH: 'Tech',
		PRIDE: 'Pride',
		FASHION_BEAUTY: 'Fashion and Beauty',
		FAIR_EXHIBITION: 'Fair and Exhibition',
		HEALTH_WELFARE: 'Health and Welfare',
		KID: 'Kid',
		GAMES_GEEK: 'Games and Geek',
		HOW_IT_WORKS: 'How it works',
		SOLUTIONS: 'Solutions',
		SERVICES: 'Services',
		PRICE: 'Price',
		INTEGRATIONS: 'Integrações',
		SUCCESSFULL_CASES: 'Successfull cases',
		CONSULTANT: 'Consultant',
		API: 'API',
		MUSIC_PARTY: 'Music and Party',
		COURSES_WORKSHOPS: 'Courses and workshops',
		CONGRESS_SEMINAR: 'Congress and Seminar',
		SPORTIVE: 'Sportive',
		ONLINE_EVENT: 'Online event',
		HELP_CENTER: 'Help center',
		BUYERS_PARTICIPANT: 'Buyers and Participant',
		EVENT_MANAGEMENT: 'Event management',
		HIDE_OPTIONS: 'Hide options',
		SHOW_OPTIONS: 'Show options',
		HOME: 'Home',
		ABOUT: 'About',
		BLOG: 'Blog',
		JOBS: 'Jobs',
		TERMS_POLICIES: 'Terms and Policies',
		CODE_CONDUCT: 'Ethics and conduct',
		COPYRIGHT: 'Sympla Internet Soluções S.A. © Copyright ${year}',
		HOMOLOGATED: 'Homologateds',
		SYMPLA_STREAMING: 'Transmissão online',
		SHOWS_AND_PARTIES: 'Parties and Shows',
		COURSES_AND_WORKSHOPS: 'Courses and Workshops',
		SPORTIVE_EVENTS: 'Sportive Events',
		GASTRONOMIC_EVENTS: 'Gastronomic Events',
		REVEILLON: 'Réveillon 2022',
		TOURS: 'Passeios e tours',
		PROSUS_LINK: 'Human Rights Policy of Prosus',
		CREATE_EVENT_AT_SYMPLA: 'Create event at Sympla',
		SPEAK_TO_A_CONSULTANT: 'Speak to a consultant',
	},
	pt: {
		BUY_FROM_APP: 'Compre pelo APP',
		FIND_EVENTS: 'Encontre Eventos',
		CITIES: 'Cidades',
		CATEGORIES: 'Categorias',
		ORGANIZERS: 'Para Produtores',
		CREATE_YOUR_EVENT: 'Crie seu evento',
		HELP: 'Ajuda',
		TODAY: 'Hoje',
		TOMORROW: 'Amanhã',
		THIS_WEEK: 'Esta semana',
		THIS_WEEKEND: 'Este fim de semana',
		NEXT_WEEK: 'Próxima semana',
		THIS_MONTH: 'Este mês',
		ALL_DATES: 'Todas as datas',
		SYMPLA_PLAY: 'Sympla Play',
		PARTIES_SHOWS: 'Festas e Shows',
		THEATER_SHOW: 'Teatros e espetáculos',
		LEARN: 'Aprender',
		ART_CULTURE_LEISURE: 'Arte, Cultura e Lazer',
		SPORT: 'Esporte',
		WELLNESS: 'Viver bem',
		CONGRESSES_TALK: 'Congressos e palestras',
		GASTRONOMY: 'Gastronomia',
		GASTRONOMIC: 'Gastronômico',
		FREE: 'Grátis',
		MEETING_NETWORKING: 'Encontro e Networking',
		RELIGION_SPIRITUALITY: 'Religião e Espiritualidade',
		ONLINE_COURSES: 'Cursos Online',
		RELIGIOUS: 'Religioso',
		TECH: 'Tecnologia',
		PRIDE: 'Pride',
		FASHION_BEAUTY: 'Moda e Beleza',
		FAIR_EXHIBITION: 'Feira e Exposição',
		HEALTH_WELFARE: 'Saúde e Bem-Estar',
		KID: 'Infantil',
		GAMES_GEEK: 'Games e Geek',
		HOW_IT_WORKS: 'Como funciona',
		SOLUTIONS: 'Soluções',
		SERVICES: 'Serviços',
		PRICE: 'Preço',
		INTEGRATIONS: 'Integrações',
		SUCCESSFULL_CASES: 'Cases de sucesso',
		CONSULTANT: 'Consultor de vendas',
		API: 'API',
		MUSIC_PARTY: 'Música e festa',
		COURSES_WORKSHOPS: 'Cursos e workshops',
		CONGRESS_SEMINAR: 'Congresso e Seminário',
		SPORTIVE: 'Esportivo',
		ONLINE_EVENT: 'Evento online',
		HELP_CENTER: 'Central de Ajuda',
		BUYERS_PARTICIPANT: 'Compradores e Participantes',
		EVENT_MANAGEMENT: 'Produtores de Eventos',
		HIDE_OPTIONS: 'Ocultar opções',
		SHOW_OPTIONS: 'Ver todas opções',
		HOME: 'Home',
		ABOUT: 'Sobre',
		BLOG: 'Blog',
		JOBS: 'Carreiras',
		TERMS_POLICIES: 'Termos e Políticas',
		CODE_CONDUCT: 'Ética e conduta',
		COPYRIGHT: 'Sympla Internet Soluções S.A. © Copyright ${year}',
		HOMOLOGATED: 'Homologados',
		SYMPLA_STREAMING: 'Transmissão online',
		SHOWS_AND_PARTIES: 'Festas e Shows',
		COURSES_AND_WORKSHOPS: 'Cursos e Workshops',
		SPORTIVE_EVENTS: 'Eventos Esportivos',
		GASTRONOMIC_EVENTS: 'Eventos Gastronômicos',
		REVEILLON: 'Réveillon 2022',
		TOURS: 'Passeios e tours',
		PROSUS_LINK: 'Política de Direitos Humanos da Prosus',
		CREATE_EVENT_AT_SYMPLA: 'Criar evento na Sympla',
		SPEAK_TO_A_CONSULTANT: 'Fale com um consultor',
	},
	es: {
		BUY_FROM_APP: 'Comprar por APP',
		FIND_EVENTS: 'Encuentra Eventos',
		CITIES: 'Ciudades',
		CATEGORIES: 'Categorías',
		ORGANIZERS: 'Para Produtores',
		CREATE_YOUR_EVENT: 'Crea tu evento',
		HELP: 'Ayuda',
		TODAY: 'Hoy',
		TOMORROW: 'Mañana',
		THIS_WEEK: 'Esta semana',
		THIS_WEEKEND: 'Este fin de semana',
		NEXT_WEEK: 'Próxima semana',
		THIS_MONTH: 'Este mes',
		ALL_DATES: 'Todas las fechas',
		SYMPLA_PLAY: 'Sympla Play',
		PARTIES_SHOWS: 'Fiestas y shows',
		THEATER_SHOW: 'Teatro y espectáculos',
		LEARN: 'Aprender',
		ART_CULTURE_LEISURE: 'Arte, Cultura y Ocio',
		SPORT: 'Deporte',
		WELLNESS: 'Vivir bien',
		CONGRESSES_TALK: 'Congresos y conferencias',
		GASTRONOMY: 'Gastronomia',
		GASTRONOMIC: 'Gastronómico',
		FREE: 'Envío',
		MEETING_NETWORKING: 'Citas y redes',
		ONLINE_COURSES: 'Cursos online',
		RELIGION_SPIRITUALITY: 'Religion y Espiritualidad',
		RELIGIOUS: 'Religioso',
		TECH: 'Tecnología',
		PRIDE: 'Pride',
		FASHION_BEAUTY: 'Moda y Belleza',
		FAIR_EXHIBITION: 'Feria y Exposición',
		HEALTH_WELFARE: 'Salud y Bienestar',
		KID: 'Infantil',
		GAMES_GEEK: 'Games e Geek',
		HOW_IT_WORKS: 'Como funciona',
		SOLUTIONS: 'Soluciones',
		SERVICES: 'Servicios',
		PRICE: 'Precio',
		INTEGRATIONS: 'Integrações',
		SUCCESSFULL_CASES: 'Casos de exito',
		CONSULTANT: 'Consultor de ventas',
		API: 'API',
		MUSIC_PARTY: 'Musica y fiesta',
		COURSES_WORKSHOPS: 'Cursos y workshops',
		CONGRESS_SEMINAR: 'Congreso y Seminario',
		SPORTIVE: 'Deportivo',
		ONLINE_EVENT: 'Evento online',
		HELP_CENTER: 'Centro de ayuda',
		BUYERS_PARTICIPANT: 'Compradores y participantes',
		EVENT_MANAGEMENT: 'Produtores de Eventos',
		HIDE_OPTIONS: 'Ocultar opciones',
		SHOW_OPTIONS: 'Ver todas las opciones',
		HOME: 'Home',
		ABOUT: 'Acerca de',
		BLOG: 'Blog',
		JOBS: 'Jobs',
		TERMS_POLICIES: 'Términos y Condiciones',
		CODE_CONDUCT: 'Ética y conducta',
		COPYRIGHT: 'Sympla Internet Soluções S.A. © Copyright ${year}',
		HOMOLOGATED: 'Homologados',
		SYMPLA_STREAMING: 'Transmissão online',
		SHOWS_AND_PARTIES: 'Fiestas y Espectáculos',
		COURSES_AND_WORKSHOPS: 'Cursos y Talleres',
		SPORTIVE_EVENTS: 'Eventos Desportivos',
		GASTRONOMIC_EVENTS: 'Eventos Gastronómicos',
		REVEILLON: 'Réveillon 2022',
		TOURS: 'Passeios e tours',
		PROSUS_LINK: 'Política de Derechos Humanos de Prosus',
		CREATE_EVENT_AT_SYMPLA: 'Crear evento en Sympla',
		SPEAK_TO_A_CONSULTANT: 'Habla con un consultor',
	},
}
