import React from 'react'

import * as styles from './Tabs.css'

type TabItem = {
  tabName: string
  ariaLabel: React.AriaAttributes['aria-label']
  label: string | React.ReactNode
}

type TabsProps = {
  tabsList?: TabItem[]
  activeTab?: string
  handleChange?(item: TabItem): void
}

const Tabs = ({ tabsList = [], activeTab, handleChange }: TabsProps) => {
  return (
    <div className={styles.Tabs} role="tablist">
      {tabsList &&
        tabsList.map((item, i) => (
          <div key={i} role="tab">
            <button
              className={`${styles.Tab} ${activeTab === item.tabName ? styles.ActiveTab : ''}`}
              onClick={() => handleChange?.(item)}
              type="button"
              aria-label={item.ariaLabel}
            >
              {item.label}
            </button>
          </div>
        ))}
    </div>
  )
}

export default Tabs
